import * as Icons from "@material-ui/icons";
import SpeechBubble from "./SpeechBubble";
import CommentFlash from "./CommentFlash";
import CommentFlashOutline from "./CommentFlashOutline";
import Eraser from "./Eraser";
import User from "./User";
import Template from "./Template";
import Prescription from "./Prescription";
import Plus from "./Plus";
import Menu from "./Menu";
import Eye from "./Eye";
import Examination from "./Examination";
import Dashboard from "./Dashboard";
import Config from "./Config";
import Chat from "./Chat";
import Calendar from "./Calendar";
import ArrowRight from "./ArrowRight";
import ArrowDown from "./ArrowDown";
import Dollar from "./Dollar";
import DollarSingle from "./DollarSingle";
import Bank from "./Bank";
import Percent from "./Percent";
import BRL from "./BRL";
import FileCopyLinkOutlined from "./FileCopyLinkOutlined";
import House from "@udok/lib/components/Icon/House";

export default {
  ...Icons,
  SpeechBubble,
  CommentFlash,
  CommentFlashOutline,
  Eraser,
  User,
  Template,
  Prescription,
  Plus,
  Menu,
  Eye,
  Examination,
  Dashboard,
  Config,
  Chat,
  Calendar,
  ArrowRight,
  ArrowDown,
  Dollar,
  DollarSingle,
  Bank,
  Percent,
  BRL,
  FileCopyLinkOutlined,
  House,
};
